import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  findCarteBySocieteApi,
  ListCartes,
} from "../../_App/Redux/Slices/cartes/carteSlice";
import {
  findCategorieBySocieteApi,
  ListCategories,
} from "../../_App/Redux/Slices/categories/categorieSlice";
import {
  findSousCategorieBySociete,
  ListSousCategories,
} from "../../_App/Redux/Slices/souscategories/souscategorieSlice";
import Select from "react-select";
import { Spinner } from "react-bootstrap";
import { societeId } from "../../_App/Redux/Slices/Auth/authSlice";
import { stockfilterApi } from "../../_App/Redux/Slices/article/articleSlice";
import {
  findProductsCaracteristiquesApiBySociete,
  ListProductCaracteristiques,
} from "../../_App/Redux/Slices/product_caracteristique/product_caracteristiqueSlice";

export default function Filtre({
  t,

  handleSearch,
  setkeyword,
  searchfiltre,
  selectedCarte,
  setSelectedCarte,
  selectedCategorie,
  setSelectedCategorie,
  setSelectedSousCategorie,
  categoriesOptions,
  setcategoriesdOptions,
  selectedSousCategorie,
  setsouscategoriesdOptions,
  selections,
  setSelections,
  souscategoriesOptions,
}: any) {
  const dispatch = useAppDispatch();

  const societeid = useAppSelector(societeId);
  const cartes = useAppSelector(ListCartes);
  const categories = useAppSelector(ListCategories);
  const sousCategories = useAppSelector(ListSousCategories);
  const carateristique = useAppSelector(ListProductCaracteristiques);

  useEffect(() => {
    if (societeid) {
      dispatch(findCarteBySocieteApi(societeid));
      dispatch(findCategorieBySocieteApi(societeid));
      dispatch(findSousCategorieBySociete(societeid));
      dispatch(findProductsCaracteristiquesApiBySociete(societeid));
    }
  }, [societeid]);
  useEffect(() => {
    if (selectedCarte?.length !== 0) {
      const listcategoriescarte = categories?.filter((item: any) =>
        selectedCarte?.includes(item.carte_id)
      );
      setcategoriesdOptions(
        listcategoriescarte?.map((option) => ({
          value: option._id,
          label: option.libelle.fr,
        }))
      );
    }
  }, [societeid]);

  useEffect(() => {
    if (selectedCarte?.length !== 0) {
      const listcategoriescarte = categories?.filter((item: any) =>
        selectedCarte?.includes(item.carte_id)
      );
      setcategoriesdOptions(
        listcategoriescarte?.map((option) => ({
          value: option._id,
          label: option.libelle.fr,
        }))
      );
    }
  }, [selectedCarte]);

  useEffect(() => {
    if (selectedCategorie?.length !== 0) {
      const ListSousCategoriescategories = sousCategories?.filter((item: any) =>
        selectedCategorie?.includes(item.categorie_id)
      );
      setsouscategoriesdOptions(
        ListSousCategoriescategories?.map((option) => ({
          value: option._id,
          label: option.libelle.fr,
        }))
      );
    } else {
      setsouscategoriesdOptions(
        sousCategories?.map((option) => ({
          value: option._id,
          label: option.libelle.fr,
        }))
      );
    }
  }, [selectedCategorie, societeid]);

  const CartesOptions = cartes?.map((option) => ({
    value: option._id,
    label: option.libelle.fr,
  }));
  useEffect(() => {
    const updatedOptions =
      sousCategories?.map((option) => ({
        value: option._id,
        label: option.libelle.fr,
      })) || [];

    setsouscategoriesdOptions(updatedOptions);
  }, [sousCategories]);

  const handleSelectChangeCartes = (selectedOption: any) => {
    const cartes = selectedOption.map((item: any) => item.value);
    setSelectedCarte(cartes);
  };
  const handleSelectChangeCategories = (selectedOption: any) => {
    const categorie = selectedOption.map((item: any) => item.value);
    setSelectedCategorie(categorie);
  };
  const handleSelectChangeSousCategories = (selectedOption: any) => {
    const souscategorie = selectedOption.map((item: any) => item.value);
    setSelectedSousCategorie(souscategorie);
  };

  const handleSelectcarateristique = (newSelectedOptions: any, item: any) => {
    setSelections({
      ...selections,
      [item._id]: newSelectedOptions,
    });
  };

  return (
    <div>
      <div className="  bg-white" style={{ padding: "10px" }}>
        <div className="row">
          {CartesOptions?.length !== 0 && (
            <div className="col-3">
              <div className="form-group">
                <label htmlFor="carte">
                  {" "}
                  <span style={{ color: "red" }}></span>
                  {t("Carte")}
                </label>
                <Select
                  isMulti
                  options={CartesOptions}
                  isSearchable={true}
                  onChange={handleSelectChangeCartes}
                  value={selectedCarte?.map((item: any) =>
                    CartesOptions?.find((cat: any) => cat.value === item)
                  )}
                />
              </div>
            </div>
          )}
          {categoriesOptions?.length !== 0 && (
            <div className="col-3">
              <div className="form-group">
                <label htmlFor="categorie">
                  {" "}
                  <span style={{ color: "red" }}></span>
                  {t("Categorie")}
                </label>
                <Select
                  isMulti
                  options={categoriesOptions}
                  isSearchable={true}
                  onChange={handleSelectChangeCategories}
                  value={selectedCategorie?.map((item: any) =>
                    categoriesOptions?.find((cat: any) => cat.value === item)
                  )}
                />
              </div>
            </div>
          )}
          {souscategoriesOptions?.length !==0 &&
          <div className="col-3">
          <div className="form-group">
            <label htmlFor="sous">
              {" "}
              <span style={{ color: "red" }}></span>
              {t("sous categorie")}
            </label>
            <Select
              isMulti
              options={souscategoriesOptions}
              isSearchable={true}
              onChange={handleSelectChangeSousCategories}
              value={selectedSousCategorie?.map((item: any) =>
                souscategoriesOptions?.find((cat: any) => cat.value === item)
              )}
            />
          </div>
        </div>
          }
          
          {carateristique &&
            carateristique.map((item, index) => (
              <div className="col-2" key={index}>
                <label htmlFor="sous">
                  <span style={{ color: "red" }}></span>
                  {item.libelle}
                </label>

                <Select
                  isMulti
                  options={
                    item.valeurs &&
                    item.valeurs?.map((v: any) => ({
                      value: { caracteristique_id: item._id, value: v },
                      label: typeof v === "string" ? v : v.libelle,
                    }))
                  }
                  isSearchable={true}
                  onChange={(newSelectedOptions) =>
                    handleSelectcarateristique(newSelectedOptions, item)
                  }
                />
              </div>
            ))}

         

          <div className="col-md-3 mt-4 mb-2">
            <div className="wrapper">
              <div className="icon-input-col6 " onClick={handleSearch}>
                {" "}
             
              </div>

              <input
                type="text"
                className="w-100 form-control product-search br-30"
                id="input-search"
                placeholder={t("mot cle")}
                onChange={(e) => setkeyword(e.target.value)}
              />
            </div>
          </div>
          <div
            className="col-1 mt-4"
            style={{ fontSize: "30px" }}
            onClick={searchfiltre}
          >
            {" "}
            <i className="fas fa-search"></i>
          </div>
        </div>
      </div>
    </div>
  );
}
